
/**
 * XMCMS Application
 * USE THIS FUNCTION INSTEAD OF local.js object IF will be overwritten
 * @author			chris 
 * @copyright (c) 	Yellowspace
 * @date      		12.10.2010
 * @version   		$Id$
 *
 * @license xmcms.js is licensed under the terms of the Open Source
 * LGPL 3.0 license. Commercial use is permitted to the extent that the 
 * code/component(s) do NOT become part of another Open Source or Commercially
 * licensed development library or toolkit without explicit permission.
 * 
 * License details: http://www.gnu.org/licenses/lgpl.html
 *
 *
 
 Articles
 	
 Last Changes
    - 2017.01.17 - added IF.$() and changed all calls to $() with this.$()
 
 */


/*******************************************************
 *******************************************************

 STARTUP

 *******************************************************
 */ function ____StartUp________() {} /*
 *******************************************************/

var page_loaded = 'no';
if(typeof OnLoadFunctions != 'object') {
	OnLoadFunctions = [];
}
function OnLoadExec() {
	for (cux=0;cux< OnLoadFunctions.length;cux++) {
		my_eval = OnLoadFunctions[cux];
		eval(my_eval);

	}
	page_loaded = "yes";
}

/*****************************************************
 */ function __P___Language() {}
/******************************************************/

if(typeof lang == 'undefined') lang = 'de';
lang_show_failed_keys = '';
if(typeof INT != 'object') INT = {};

function $L(key,o) {

	this.lang = lang;
	this.def_lang = 'en';

	if(!INT[key]) {
		return key+lang_show_failed_keys;
	} else if(INT[key]) { // key found

		var found_str = '';

		if(INT[key][this.lang]) found_str = INT[key][this.lang];
		else if(INT[key][this.def_lang]) found_str = INT[key][this.def_lang];
		else found_str = key+lang_show_failed_keys;

		if(typeof o == 'object') {
			var oidx=0;
			for(okey in o) {
				found_str = str_replace('$$'+okey,o[okey], found_str);
				oidx++;
			}
		}

		return found_str;

	} else return key+lang_show_failed_keys;
}



/**
 * Generate Dynamic IDs
 * @constructor
 */
function Generator() {};
Generator.prototype.rand =  Math.floor(Math.random() * 26) + Date.now();
Generator.prototype.getId = function() {
	return 'gen-' + this.rand++;
};
var idGen = new Generator();

if(typeof Ext == 'object') {
	Ext.require('Ext.Element');
	//Ext.require('Ext.Element.*');
	//Ext.require('Ext.fx.*');
}

function Interface () {
	
	this.window = false;
	this.title = '';
	
	this.init = function() {		
		// lightbox
		this.loginBoxAnimation();
	};
	
	//---------------------------------------------------------------------------------------
	this.setLang = function(lang) {
		document.location.href = '/'+lang+myPlainPagePath;		
	};

	this.switchLang = function(lang_key) {
		// Expl: IF.switchLang('de');
		var found = false;
		var url = document.URL;
		var langs = ['en','es','de','it','fr'];
		var curr_lang = "/" + lang_key + "/";
		for(lang in langs) {
			if (url.match(langs[lang])) {
				var found = url.replace(url,curr_lang);
				break;
			}
		}

		if(found) document.location.href=found;
	};
	
	//---------------------------------------------------------------------------------------
	this.loginBoxAnimation = function() { 
		// show settings icon and show loginbox on mouseenter
		
		this.loginbox = $('#loginbox'); 
		if(this.loginbox && this.loginbox.hasClass('logout')) {
			
			var hlcs = $('.hidden-login-click');
			if(!hlcs) return false;
			hlcs.html('<img src="/javascripts/ext/plugins/img/silk/icons/cog.png" />');
			
			hlcs.on('click',false,function(e){
				var _this = $( this );
				IF.loginbox.fadeIn({
					easing: 'swing',
					duration: 500
				});
								
				IF.addEvent(document,'mousedown',IF.loginboxhider = function(event) {
					var el;
					el = IF.gettarget(event);
					var xEL = $(el);
					var loginbox = IF.$(IF.loginbox.id);
					var test = xEL.parent('#loginbox');
					// If the active button was clicked on, exit.
					if ((test && test.length>0) || (el == loginbox)) return;
					
					IF.loginbox.fadeOut({
						easing: 'swing',
						duration: 500
					});
					IF.removeEvent(document,IF.loginboxhider);
					
					}.bind(IF)
				);

			});

			//this.loginbox.css('opacity',0); 
		}
	};
	
	//---------------------------------------------------------------------------------------
	this.isDevice = function() {
		nav = navigator;
		ua = nav.userAgent.toLowerCase();		
		check = function(r){
			return r.test(ua);
		};
		var devIsDevice = check(/iphone|ipod|ipad|android/gi);
		if(devIsDevice) {
			return true;
		} else return false;
	};


	//---------------------------------------------------------------------------------------
	this.loginOperation = function(operation) {
		document.forms['iflogin'].operation.value = operation;
		document.forms['iflogin'].submit();
	};

	//---------------------------------------------------------------------------------------
	this.logout = function() {
		document.forms['iflogin'].operation.value = '[logout]';
		//document.forms['iflogin'].action = '/';
		document.forms['iflogin'].submit();
	};
	
	//---------------------------------------------------------------------------------------
	this.toggleMode = function() {
		if(mode) mode_value = mode;
		else mode_value = "browse";
		switch (mode_value) {
			case"admin":
				document.getElementById("mymode").value = 'browse';
				break;
			case"browse":
				document.getElementById("mymode").value = 'admin';
				break;
		}
		document.forms['iflogin'].submit();
	};

	//---------------------------------------------------------------------------------------
	this.rot13init = function() {
	  var map = new Array();
	  var s   = "abcdefghijklmnopqrstuvwxyz";
	  
	  for (i=0; i<s.length; i++)
		map[s.charAt(i)]            = s.charAt((i+13)%26);
	  for (i=0; i<s.length; i++)
		map[s.charAt(i).toUpperCase()]  = s.charAt((i+13)%26).toUpperCase();
	  return map;
	};
	
	this.rot13map = false;
	this.rot13=function(a) {
	  if (!this.rot13map) this.rot13map=this.rot13init();
	  s = "";
	  for (i=0; i<a.length; i++) {
		  var b = a.charAt(i);
		  s += (b>='A' && b<='Z' || b>='a' && b<='z' ? this.rot13map[b] : b);
		}
	  return s;
	};
	
	this.decMailto=function(eAddr) {
		document.location = 'mailto:'+this.rot13(eAddr);
		return false;
	};

	//---------------------------------------------------------------------------------------
	this.str_replace = function(search, replace, subject) {
	  var result = "";
	  var  oldi = 0;
	  for (i = subject.indexOf (search)
		 ; i > -1
		 ; i = subject.indexOf (search, i))
	  {
		result += subject.substring (oldi, i);
		result += replace;
		i += search.length;
		oldi = i;
	  }
	  return result + subject.substring (oldi, subject.length);
	};
	
	//---------------------------------------------------------------------------------------
	this.disable = function(obj) {

		//if(typeof console == 'object') console.log('x obj: %o',obj);
		for(id in obj) {
			//if(typeof console == 'object') console.log('x obj.id: %o',obj.id);
			if(obj && obj.id && obj.id.length >0 && this.$(obj.id)) {
				this.$(obj.id).addClsName('disabled');
				this.$(obj.id).disabled = true;
			}
		}			
	};
	
	this.enable = function(obj) {
		for(id in obj) {
			//DebugDump(obj.id,id);
			if(obj && obj.id && obj.id.length >0 && this.$(obj.id)) {
				this.$(obj.id).removeClsName('disabled');
				this.$(obj.id).disabled = false;
			}
		}
	};
	
	//---------------------------------------------------------------------------------------
	this.hideContainer = function(obj) {
		for(id in obj) {
			if(this.$(obj.id)) {
				this.$(obj.id).addClsName('hidden');
			}
		}			
	};
	
	this.showContainer = function(obj) {
		for(id in obj) {
			if(this.$(obj.id)) {
				this.$(obj.id).removeClsName('hidden');
			}
		}
	};

	//---------------------------------------------------------------------------------------
	this.gettarget = function(event) {

		if(window
		&& (window.event)
		&& (window.event.srcElement)) {
			t = window.event.srcElement;
		} else {
			t = (event.target.tagName ? event.target : event.target.parentNode);
		}
		return t;
	};
	

	//---------------------------------------------------------------------------------------
	this.addEvent = function(el,evname,func) {
	
		if (el.attachEvent) { // IE
			el.attachEvent("on" + evname, func);
		} else if (el.addEventListener) { // Gecko / W3C
			el.addEventListener(evname, func, true);
		} else {
			el["on" + evname] = func;
		}
	
	};
	
	//---------------------------------------------------------------------------------------
	this.removeEvent = function(el,evname,func) {
	
		if (el.detachEvent) { // IE
			el.detachEvent("on" + evname, func);
		} else if (el.removeEventListener) { // Gecko / W3C
			el.removeEventListener(evname, func, true);
		} else {
			el["on" + evname] = null;
		}
	
	};

	//---------------------------------------------------------------------------------------
	this.$ = function() {
		var elements = new Array();

		for (var i = 0; i < arguments.length; i++) {
			var element = arguments[i];
			if (typeof element == 'string')
				element = document.getElementById(element);

			if (arguments.length == 1)
				return element;

			elements.push(element);
		}

		return elements;
	};
}

IF = new Interface(); 
OnLoadFunctions[OnLoadFunctions.length] = "IF.init();";

var reg = 'phpos'; 
var is_phpos_environment = document.location.href.match(reg);
if(!is_phpos_environment) {
	browserSubmit = IF.browserSubmit;
	setDirPath = IF.setDirPath;
	changeDirPath = IF.changeDirPath;
}

